<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.prospects')" :button="{ title: trans('prospects.create'), modal: 'add-prospect-modal'} " />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="!projectProspects" class="loading">
          {{ trans('global.loading') }}
        </div>
        <div v-else>
          <b-row>
            <b-col v-if="projectProspects.length === 0">
              {{ trans('prospects.none_found') }}
            </b-col>
            <b-col v-for="prospect in projectProspects" v-else :key="prospect._id" sm="12">
              <b-card class="margin-top-16">
                <b-row>
                  <b-col>
                    <b-link :to="'/projects/' + project.slug + '/sales/' + prospect.slug">
                      {{ prospect.title }}
                    </b-link>
                  </b-col>
                  <b-col>
                    {{ prospect.slug }}
                  </b-col>
                  <b-col
                    class="text-right"
                  >
                    <b-button
                      v-b-modal.copy-prospect-modal
                      variant="primary"
                      size="md"
                      class="demo"
                      @click="setProspectData(prospect)"
                    >
                      {{ trans('prospects.copy_prospect') }}
                    </b-button>

                    <b-button
                      variant="danger"
                      size="md"
                      class="demo ml-2"
                      @click="deleteProspect(prospect._id)"
                    >
                      Slett
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <ModalCopyProspect :prospect-data="prospectData" :project-data="project"></ModalCopyProspect>
  </b-container>
</template>

<script>
  import { mapState } from 'vuex'
  import Heading from '../../../Core/Heading'
  import ModalCopyProspect from './Prospect/Modals/CopyProspect'

  const fb = require('../../../../firebaseConfig')

  export default {
    name: 'ProjectProspects',
    components: {
      Heading,
      ModalCopyProspect
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      projectType: {
        type: String,
        required: true
      }
    },
    data: () => ({
      prospectData: {}
    }),
    computed: {
      ...mapState({
        prospects: state => state.prospects.prospects
      }),
      projectProspects () {
        return this.prospects.filter(prospect => prospect.projectId === this.project._id)
      }
    },
    methods: {
      setProspectData (prospect) {
        this.prospectData = prospect
      },
      deleteProspect (prospectId) {
        console.log('Deleting prospect: ', prospectId)
        const prospect = this.prospects.find(prospect => prospect._id === prospectId)

        if (prospect) {
          this.$bvModal.msgBoxConfirm('Vil du virkelig slette ' + prospect.title, {
            title: 'Bekreft sletting av prospektet: ' + prospect.title,
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'Slett',
            cancelTitle: 'Avbryt'
          }).then((value) => {
            if (value) {
              fb.prospectsCollection.doc(prospect._id).update({
                deleted: true
              }).then(() => {
                this.$bvToast.toast(prospect.title + ' ble slettet', {
                  title: 'Sletting utført!',
                  variant: 'success',
                  solid: true
                })
              })
            }
          })
        } else {
          console.log('Error: Prospect was not found...')
        }
      }
    }
  }
</script>
