<template>
  <b-modal
    id="copy-prospect-modal"
    scrollable
    size="lg"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <template v-slot:modal-header>
      <b-container>
        <b-row>
          <b-col>
            <h3>{{ trans('prospects.copy_prospect') }}</h3>
            <p class="description">
              {{ trans('prospects.copy_prospect_desc') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:default>
      <b-container>
        <b-row>
          <b-col>
            <b-form class="margin-bottom-16" @submit.prevent="copyProspect">
              <b-form-group
                id="input-name-group"
                :label="trans('prospects.form_copy.name')"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="$v.form.name.$model"
                  class="form-control-lg"
                  :state="validateState('name')"
                  type="text"
                  aria-describedby="name-live-feedback"
                  trim
                >
                </b-form-input>

                <b-form-invalid-feedback id="name-live-feedback">
                  {{ trans('prospects.form_copy.name_error') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row v-if="inProgress">
          <b-col>
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="mb-0 font-weight-bold">
                Kopierer prospektfiler... ({{ processedFiles }} av {{ totalFiles }})
              </p>
              <p class="mb-0 font-size-small">
                Batch {{ currentBatch }}/{{ totalBatches }}
              </p>
            </div>
            <b-progress
              height="2rem"
              :max="totalFiles"
              animated
            >
              <b-progress-bar
                :value="processedFiles"
                :label="`${((processedFiles / totalFiles) * 100).toFixed()}%`"
              ></b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button
        variant="light"
        size="md"
        class="padding-left-60 padding-right-60"
        :disabled="inProgress"
        @click="cancel()"
      >
        {{ trans('global.cancel') }}
      </b-button>
      <b-button
        ref="submitbtn"
        variant="primary"
        size="md"
        class="padding-left-60 padding-right-60"
        :disabled="inProgress"
        @click="copyProspect"
      >
        <span v-if="!loading">{{ trans('prospects.save') }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { minLength, required } from 'vuelidate/lib/validators'
  import { mapState } from 'vuex'

  const cloneDeep = require('clone-deep')
  const fb = require('../../../../../../firebaseConfig')

  let snapDocUnsubscribe

  export default {
    name: 'ModalCopyProspect',
    mixins: [validationMixin],
    props: {
      prospectData: {
        type: Object,
        required: true
      },
      projectData: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      form: {
        name: ''
      },
      formSubmitted: false,
      loading: false,
      inProgress: false,
      totalFiles: 0,
      processedFiles: 0,
      currentBatch: 1,
      totalBatches: 0
    }),
    validations () {
      return {
        form: {
          name: {
            required,
            minLength: minLength(4)
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      })
    },
    methods: {
      /**
       * validateState (name)
       * Makes sure we only check for errors when we hit the submit-button
       * @param name
       * @returns {null|*}
       */
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]

        if (this.formSubmitted) {
          return $dirty ? !$error : null
        } else {
          return null
        }
      },
      async copyProspect () {
        this.loading = true
        this.formSubmitted = true

        this.$v.form.$touch()

        if (this.$v.form.$anyError) {
          this.loading = false
          return
        }

        const sourceProspect = this.prospectData
        let targetProspect = cloneDeep(sourceProspect) // Remove refs to source prospect

        const title = this.form.name
        const slug = this.createSlug(title, 'prospects/slugHelper', sourceProspect._id)

        const varycode = this.doPaddy(this.$store.state.configs.varyCode)

        targetProspect.createdOn = new Date()
        targetProspect.title = title
        targetProspect.deleted = false
        targetProspect.slug = slug
        targetProspect.userId = this.userProfile.uid
        targetProspect.varycode = varycode
        delete (targetProspect._id)

        // Create the new prospect-path
        const sourcePath = 'projects/' + this.projectData.varycode + '/prospects/' + sourceProspect.varycode
        const targetPath = 'projects/' + this.projectData.varycode + '/prospects/' + targetProspect.varycode

        await fb.varycodesCollection.doc(varycode).set({
          createdOn: new Date(),
          varycode,
          userId: this.userProfile.uid,
          type: 3 // 1 = project, 2 = unit, 3 = prospect
        }).then(() => {
          fb.prospectsCollection.add(targetProspect).then((res) => {
            if (res.id !== undefined && res.id !== '') {
              fb.varycodesCollection.doc(varycode).set({
                typeId: res.id
              }, { merge: true })
            }
          })
        }).catch((err) => {
          console.log('ERRORS', err)
        })

        const fixPaths = (prospectObj) => {
          Object.entries(prospectObj).forEach(([key, value]) => {
            if (prospectObj[key] !== undefined &&
              typeof prospectObj[key] === 'object' &&
              prospectObj[key] !== null) {
              fixPaths(prospectObj[key])
            } else if (key === 'path') {
              prospectObj[key] = value.replace(sourcePath, targetPath)
            } else {
              prospectObj[key] = value
            }
          })

          return prospectObj
        }
        targetProspect = fixPaths(targetProspect)

        const rId = this.randomTxt(16) // randomId for support for multiple copy-instances from the same project
        /**
         * Set up a async process with multiple https triggered cloud functions to avoid timeout
         */
        const duplicateFolderInit = fb.func.httpsCallable('backendStorageDuplicateFolderInit')
        const filesArrayResult = await duplicateFolderInit(
          {
            sourceId: sourceProspect._id,
            source: sourcePath,
            rId
          }
        ).then((res) => {
          return res
        }).catch((err) => {
          console.log('ERRRORS:', err)
          return { data: {} }
        })

        await this.enableLiveUpdates(rId)

        const filesArray = filesArrayResult.data
        this.totalBatches = Math.floor(filesArray.length / 20)

        const duplicateFoldersInBatches = fb.func.httpsCallable('backendStorageDuplicateFoldersInBatches')
        while (filesArray.length) {
          await duplicateFoldersInBatches({
            filesArray: filesArray.splice(0, 20),
            sourceId: sourceProspect._id,
            source: sourcePath,
            target: targetPath,
            deletesource: false,
            rId
          }).catch((err) => {
            console.log('BatchERRORS:', err)
          })

          if (this.currentBatch < this.totalBatches) this.currentBatch++
        }
      },

      async enableLiveUpdates (rId) {
        await new Promise(resolve => setTimeout(resolve, 1000))

        const snapDoc = fb.snapsCollection
          .doc(
            this.userProfile.uid +
              '-' +
              this.prospectData._id +
              '-' +
              rId
          )
        snapDocUnsubscribe = snapDoc.onSnapshot((doc) => {
          if (doc.exists) {
            const snapDocData = doc.data()

            this.inProgress = true
            this.totalFiles = snapDocData.totalFiles
            this.processedFiles = snapDocData.processedFiles

            console.log('TotalFiles:', this.totalFiles)
            console.log('ProcessedFiles:', this.processedFiles)

            if (this.totalFiles === this.processedFiles || this.totalFiles < this.processedFiles) {
              snapDocUnsubscribe()
              this.loading = false
              this.$root.$emit('bv::hide::modal', 'copy-prospect-modal')
            }
          } else {
            snapDocUnsubscribe()
            this.enableLiveUpdates()
          }
        })

        return true
      }
    }
  }
</script>
